#portfolio {
    margin-top: 40px;
}

.portfolio {
    width: 100%;

}

.section__portfolio__container {
    width: 100%;
    height: 100%;
}

.porfolio__name {
    height: 63px;
    width: 80%;
    margin: 0 auto;


    display: flex;
    align-items: center;
    justify-content: space-between;
}

.porfolio__name-name {
    height: 100%;
    position: relative;

}

.porfolio__name-name p {

    font-family: Montserrat;
    font-size: 52px;
    font-weight: 600;
    line-height: 63.39px;
    text-align: left;

    color: #fff;
}

.porfolio__name-name img {
    position: absolute;
    right: -20px;
    bottom: 13px;

}

.porfolio__name-link {
    width: 270.6px;
    height: 48px;

}

.porfolio__name-link button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1F9D96;
    border-radius: 40px;
    border: 1px solid #fff;
}

.porfolio__name-link button p {
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.38px;
    text-align: center;
    margin-right: 14px;
}

.porfolio__container__swiper {
    width: 100%;
    height: 767px;
    margin-top: 30px;
}

.swiper__block_porfolio {

    height: 100%;
    width: 100%;
    border: 2px solid white;
    border-bottom: none;
    /* Remove bottom border */
    border-radius: 30px 30px 25px 25px;
    /* Adjust the border-radius as needed */
}

.swiper__border__left {
    content: "";
    position: absolute;

    left: 0px;
    /* Align with the padding */
    /* Align with the padding */
    bottom: 0px;
    /* Position at the bottom */
    height: 50px;
    width: 99px;
    /* Thickness of the border */
    border-radius: 0px 0px 0px 25px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
}

.swiper__border__right {
    content: "";
    position: absolute;

    right: 0px;
    /* Align with the padding */
    /* Align with the padding */
    bottom: 0px;
    /* Position at the bottom */
    height: 50px;
    width: 360px;
    /* Thickness of the border */
    border-radius: 0px 0px 25px 0px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
}

.porfolio__wrapper__swiper {
    width: 100%;
    height: 100%;
}

.porfolio__wrapper__swiper .swiper-slide {
    width: 767px;
    /* Set the width for each slide */
    height: 598px;
    /* Set the height for each slide */
    margin-right: 43px;
    /* Set right margin for spacing between slides */
}

.swiper__button {
    position: absolute;
    min-width: 150px;
    height: 55px;

    bottom: 0;
    left: 99px;
    bottom: -26px;
}

.swiper__button a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.swiper__button_image {
    height: 54px;
    width: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1F9D96;
    border-radius: 19px;
    margin-right: 12px;

}

.swiper__button a p {

    font-size: 18px;
    font-weight: 600;
    line-height: 21.94px;
    text-align: left;
    color: #fff;
}

.swiper__block_porfolio {
    overflow: visible;
}

.swiper-slide {
    overflow: visible;
}

.swiper__border__right1 {
    width: 500px;
}
.swiper__border__right2{
    width: 450px;
}
.swiper__border__right3{
    width: 390px;
}
.swiper__border__right4{
    width: 495px;
}
.swiper__border__right5{
    width: 458px;
}
.swiper__border__right6{
    width: 496px;
}
.swiper__border__right7{
    width: 540px;
}

.swiper__button_image1 {
    background-color: #404040;
    box-shadow: 0px 0px 24px 0px #404040;

}
.swiper__button_image2{
    background-color: #D0BFC4;
    box-shadow: 0px 0px 24px 0px #D0BFC4;
}
.swiper__button_image3{
    background-color: #6C5D31;
    box-shadow: 0px 0px 24px 0px #6C5D31;
}
.swiper__button_image4{
    background-color: #ECB101;
    box-shadow: 0px 0px 24px 0px #ECB101;
}
.swiper__button_image5{
    background-color: #CE2525;
    box-shadow: 0px 0px 24px 0px #CE2525; 
}
.swiper__button_image6{
    background-color: #273049;
    box-shadow: 0px 0px 24px 0px #273049; 
}
.swiper__button_image7{
    background-color: #4682B4;
    box-shadow: 0px 0px 24px 0px #4682B4;  
}


.swiper__image__container {
    position: absolute;
    width: calc(100% + 6px);
    height: 389px;
    left: -1px;
    top: -1px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.swiper__image__container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper__portfolio__quotes {
    position: absolute;
    bottom: 190px;
    left: 104px;
    transform: scale(0.8);
}


.swiper__portfolio__desc {
    width: 641px;

    position: absolute;
    height: 150px;
    bottom: 20px;
    left: calc(50% - 320.5px);
}

.swiper__portfolio__desc p {
    font-size: 12px;
    font-weight: 400;
    line-height: 17.93px;
    text-align: left;
    color: #fff;
}
.swiper__portfolio__desc span {
    font-size: 12px;
    font-weight: 600;
    line-height: 17.93px;
    text-align: left;

}


.swiper__portfolio__additional-photo{
    width: 455px;
    height: 78px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 200;
    right: 15px;
    bottom: 180px;
}
.swiper__portfolio__additional-photo img {
    max-width: 146px;
}



.porfolio__wrapper__swiper .swiper-slide:first-child {
    margin-left: 180px;
    /* Set left margin for the first slide */
}
.porfolio__name-link-mobile{
    display: none;
    visibility: hidden;
}

/*************************/

@media only screen and (max-width: 768px) {
    .porfolio__wrapper__swiper .swiper-slide:first-child{
        margin-left: 20px;
    }
    .swiper__image__container {
        height: 168px;
    }
    .swiper__portfolio__quotes{
        bottom: initial;
        bottom: initial;
        top: 144px;
        left: 32px;
    transform: scale(0.4);
    }
    
    .swiper__portfolio__additional-photo {
        width: 310px;
        height: 64px;
        bottom: 256px;
    }
    .swiper__portfolio__additional-photo img{
        max-width: 108px;
    }
    .swiper__portfolio__desc{
        width: 300px;
        height: 223px;
        bottom: 25px;
        left: calc(50% - 150px);
    }
    .swiper__portfolio__desc p{
        font-size: 10px;
    }
    .swiper__portfolio__desc span{
        font-size: 10px;
    }

    .swiper__border__left {
        width: 60px;
    }
    .swiper__button {
        left: 59px;

    }.swiper__border__right {
        width: 100px;
    }
    .swiper__border__right2 {
        width: 52px;
    }
    .swiper__border__left3{
        width: 24px;
    }
    .swiper__button3{
        left: 23px;
    }
    .swiper__border__right3 {
        width: 27px;
    }
    .swiper__border__right5{
        width: 61px;
    }
    .swiper__border__right7 {
        width: 146px;
    }
    .porfolio__name{
        flex-direction: column;
        align-items: center;
        height: initial;
    }
    .porfolio__name-link {
        margin-top: 20px;
    }
    .porfolio__name-link-pc{
        display: none;
        visibility: hidden;
    }
    .porfolio__container__swiper{
        height: 530px;
    }
    .porfolio__name-link-mobile{
        visibility: visible;
        display: block;
        margin: 0 auto;
        margin-top: 30px;
    }
    .swiper__button a p{
        font-size: 15px;
    }
}
@media only screen and (max-width: 360px) {
    .porfolio__name-name p {
      font-size: 41px;

    }
    .porfolio__name-name img{
        width: 10px;
        height: 10px;
        bottom: 17px;
    }
}