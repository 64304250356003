@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/**! ***COLORS*************/
/**! ***COLORS*************/
/**! ***COLORS*************/
:root {
  --blue_azure: #1F9D96;
  --white: #fff;
  --form__back:  #0000001A;
  
}



* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
 /* height: 100%;*/
 min-height: 1500px;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: "Montserrat", sans-serif;
  background-color: #000;
  color: #fff;
}
html {
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 0;
}

body.lock {
  overflow: hidden;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-ms-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
}
.main__wrapper {
  padding-top: 60px; /* Ensure there is enough padding at the top */
  width: 100%;

}
.main__background__placeholder{
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;
  background-image: url('./assets/main_back.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  
}
.main__placeholder__background{
  width: 100%;
  height: 100%;
  background: rgb(0,0,0);
  background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.2861519607843137) 49%, rgba(0,0,0,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.2861519607843137) 49%, rgba(0,0,0,1) 100%);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.2861519607843137) 49%, rgba(0,0,0,1) 100%);

  
  position: absolute;
  z-index: 200;
  top: 0;
  opacity: 1;
}
.main__placeholder__blur{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 500;
  background-color: #00000010;
  top: 0;
  opacity: 1;
  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);
  backdrop-filter: blur(2px);
}

.main__placeholder__content{
  position: relative;
z-index: 1000;
  padding-top: 50px;
  height: 100%;
  
}
.main__container{
  width: 100%;
}
/* Disable scrolling */
.no-scroll {
  overflow: hidden;
}

@media only screen and (max-width: 1400px) {
  .main__wrapper {
    padding-top: 30px;
  }
}
@media only screen and (max-width: 790px) {

  .main__placeholder__content{
    overflow: hidden;
  }
}


  
