.scroll-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    padding: 10px;
    height: 40px;
    width: 40px;
    font-size: 24px;

    color: #fff;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    z-index: 900;
    opacity: 0.8;
    transition: opacity 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;


    width: 56px;
    height: 56px;

    background: linear-gradient(180deg, #07E6D9 10%, #1C423F 80.58%);

    box-shadow: #ffffff4f 0px -2px 10px;
  }
  
  .scroll-to-top:hover {
    opacity: 1;
  }
  
  @media only screen and (max-width: 768px) {
    .scroll-to-top {
      bottom: 20px;
      right: 20px;
      font-size: 18px;
    }
  }
  