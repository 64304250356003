.header {
    width: 100%;
    height: 65px;
    position: sticky; /* Use sticky positioning */
    top: 0px; /* Start 72px from the top of the page */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 48px;
    z-index: 1000; /* Ensure the header is above other content */
    transition: background-color 0.1s ease-in-out; /* Smooth background transition */
  }
  
  .header.with-background {
    background-color: rgba(0, 0, 0, 0.8); /* Add background when sticky or scrolls down */
  }
  
  .header.sticky {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional shadow when sticky */
  }


.header__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
}

.header__logo {
    height: 31.5px;
    width: 146px;
}

.header__logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header__navigation__lang {
    display: flex;
    height: 100%;
}

.header__navigation {
    height: 100%;
}

.header__navigation ul {
    height: 100%;
    align-items: center;
    display: flex;
}

.header__navigation ul li {
    margin: 0px 15px;
    font-weight: 600;
    color: var(--white);
}

.header__navigation ul li button {
    color: var(--white);
    font-size: 20px;
    position: relative;
    background-color: rgba(0, 0, 0, 0.0);
}

.header__navigation ul li button::before {
    content: "";
    width: 51px;
    height: 1px;
    position: absolute;
    bottom: -4px;
    right: -4px;
    display: none;
    background-color: var(--blue_azure);
}

.header__navigation ul li button:hover::before {
    display: initial;
}

.header__lang {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
    cursor: pointer;

    width: 50px;
    
}
.header__navigation__lang-mobile{
  display: none;
  visibility: hidden;
}
/********** language toggle***/
/* Positioning for the language dropdown */
.header__lang {
    position: relative; /* Necessary to position the dropdown below the icon */
  }
  
  .header__lang-dropdown {
    position: absolute;
    top: 51px;
    left: -10px;
    background: linear-gradient(180deg, #07E6D9 10%, #1C423F 80.58%);
    width: 60px;
    display: flex;
    flex-direction: column;
    box-shadow: #ffffff4f 0px -2px 10px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    z-index: 1000; /* Ensure it appears above other content */
  }
  
  .header__lang-dropdown ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .header__lang-dropdown button {
    padding: 8px 5px;
    cursor: pointer;
    color: white;background-color: rgba(0, 0, 0, 0.0);
    
  }
  .header__lang-dropdown button:nth-child(2){
    border-top: 0.5px #e3e3e34f solid;
    border-bottom: 0.5px #e3e3e34f solid;;
  }
  
  .header__lang-dropdown button:hover {
    background-color: #fff;
    color: #1C423F;
  }
  .header__navigaion__lang-mobile{
    display: none;
    visibility: hidden;
  }

@media only screen and (max-width: 790px) {
  .header {

  }
    .header__navigation__lang{
        display: none;
    }

.header__navigation__lang-mobile
{
  display: initial;
  visibility: visible;
}
}