/* Burger menu button */
.burger-menu-button {
    position: relative;
    width: 30px;
    height: 20px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .burger-menu-button::before,
  .burger-menu-button::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: #fff;
    left: 0;
    transition: all 0.3s ease;
  }
  
  .burger-menu-button::before {
    top: 0;
  }
  
  .burger-menu-button::after {
    bottom: 0;
  }
  
  .burger-menu-button.open::before {
    opacity: 0;
    top: 9px;
  }
  
  .burger-menu-button.open::after {
    opacity: 0;
    bottom: 9px;
  }
  
  .burger-menu-button.open {
    background-color: transparent;
  }
  
  /* Mobile menu with slide-in animation */
  .mobile-menu {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: -102%; /* Initially hidden off-screen */
    width: 100%;
    height: 100vh; /* Full height of the viewport */
    background-color: #191c29;
    border-image-source: linear-gradient(152.69deg, #433E42 3.41%, rgba(0, 0, 0, 0) 82.98%);
    z-index: 100;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: left 0.5s ease-in-out; /* Smooth sliding animation */
  }
  .mobile-menu-wrapper{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #191c29;

  }

  .mobile-menu-wrapper::before {
    content: "";
    width: 104%;
    height: 102%;
    border-radius: 8px;
    background-image: linear-gradient(
        var(--rotate)
        ,#1f9d96, #3c67e3 50%, #4e00c2);
      position: absolute;
      z-index: -1;
      top: -1%;
      left: -2%;
      animation: spin 2s linear infinite;
  }

  .mobile-menu-wrapper::after {
    position: absolute;
    content: "";
    top: calc(var(--card-height) / 6);
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    transform: scale(0.8);
    filter: blur(calc(var(--card-height) / 6));
    background-image: linear-gradient(
        var(--rotate)
        , #5ddcff, #3c67e3 43%, #4e00c2);
      opacity: 1;
    transition: opacity .5s;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      --rotate: 0deg;
    }
    100% {
      --rotate: 360deg;
    }
  }



  .mobile-menu-logo{
    padding-top: 20px;
    padding-left: 20px;
  }


  .mobile-menu.open {
    left: 0; /* Slide in when open */
  }
  
.mobile-menu-navigation{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding-top: 40px;
    position: absolute;
    width: 240px;
    left: calc(50% - 120px);

    z-index: 400;
    height: 350px;
    justify-content: space-evenly;
}
.mobile-poligon{
    position: absolute;
    width: 100%;
}
.mobile-poligon2{
left: 0;

}


.mobile-menu-navigation button{
    position: relative;
}
.mobile-menu-navigation button:active {
    background-color: #1f9d96; /* Change background color when pressed */
    color: #fff;
  }
  .mobile-menu-navigation button:focus {
    outline: none; /* Remove the default focus outline */
    background-color: #1f9d96; /* Different background color when focused */
    color: #fff;
  }
  .mobile-menu-navigation button:active::before{
    height: 0px;
  }
.mobile-menu-navigation button::before{
    content: "";
    width: 50px;
    height: 1px;
    background-color: #1f9d96;
    position: absolute;
    bottom: -1px;
    left: calc(50% - 25px);
}
  .mobile-menu button {
    background: none;
    border: none;
    font-size: 24px; /* Adjust font size */
    color: #fff;
    cursor: pointer;
  }
  
  /* Close button */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 36px;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 800;

  }
  .mobile-menu-created{
    position: absolute;
    bottom: 0;

    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .close-button{
    background: initial;
    width: 50px;
    height: 50px;

  }

  .header__lang-dropdown button:nth-child(2){
    border: none;
  }

  /* Mobile Menu and Burger Icon visibility */
  @media (max-width: 768px) {
    .header__navigation__lang {
      display: none;
    }
  
    .header__navigation__lang-mobile {
      display: block;
    }
  
    .burger-menu-button {
      display: block;
    }
    .active-lang {
        background: #1f9d96; /* You can adjust this color as needed */
      }
  }
  