#services {
  margin-top: 75px;
  min-height: 100vh;
  width: 100%;

  background-image: url("../assets/daker_back.png");
  background-size: cover;
  background-repeat: no-repeat;
}

/* services.css */
.section__services__container {
  background-color: #00000066;
  width: 100%;
  min-height: 100vh;
  padding: 0; /* Ensure there's no padding */
  margin: 0; /* Ensure there's no margin */

  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 10%,
    rgba(255, 255, 255, 0) 52%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 10%,
    rgba(255, 255, 255, 0) 52%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 10%,
    rgba(255, 255, 255, 0) 52%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}

.section__services__background {
  width: 100%;
  min-height: 100vh;
  background-color: #00000066;
}
.services__name {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px; /* Optional: Add padding to the services name */
}

.services__name__block {
  display: flex;
  align-items: flex-end;
  height: 50px;
  position: relative;
}

.services__name__block p {
  font-size: 52px;
  font-weight: 600;
  text-align: left;
}

.services__name__block img {
  position: absolute;
  right: -20px;
  bottom: 7.5px;
}

.services__wrapper__swiper {
  width: 100%;
  height: 510px; /* Set the height for the Swiper container to match the slide height */
  margin: 0; /* Ensure there's no margin */
  padding: 0; /* Ensure there's no padding */
  margin-top: 110px;
}

.swiper {
  height: 100%;
  width: 100%;
}

.swiper-container {
  width: 100%;
  height: 100%;
  margin: 0; /* Ensure there's no margin */
  padding: 0; /* Ensure there's no padding */
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #000;
  width: 466px; /* Set the width for each slide */
  height: 510px; /* Set the height for each slide */
  margin: 0; /* Ensure there's no margin */
  padding: 0; /* Ensure there's no padding */
}

.swiper__block {
  width: 100%;
  height: 100%;
  border: 1px solid #433e42;
  background: rgb(67, 62, 66);
  background: -moz-linear-gradient(
    180deg,
    rgba(67, 62, 66, 0.2699054621848739) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(67, 62, 66, 0.2699054621848739) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(67, 62, 66, 0.2699054621848739) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#433e42",endColorstr="#ffffff",GradientType=1);
  border-radius: 16px;
  backdrop-filter: blur(30px); /* Adjust the blur radius */
  -webkit-backdrop-filter: blur(30px); /* Adjust the blur radius for Safari */
}

.swiper__content__container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 41px;
}

.swiper__number {
  width: 100%;
  text-align: right;

  font-size: 128px;
  font-weight: 600;

  text-align: right;
  color: #ffffff1a;
}
.swiper__image {
  height: 99px;
}

.swiper_name {
  font-size: 32px;
  font-weight: 600;
  line-height: 39.01px;
  text-align: left;
  color: #ffffff;
  max-width: 140px;
  height: 78px;
  margin-bottom: 20px;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.swiper__desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 20.92px;
  text-align: left;
  color: #fff;
}






/* Second Swiper Specific Styles */
.section__second__services {
  height: 505px;
  width: 95%;
  margin: 0 auto;
  position: relative;
  margin-top: 50px;
}

.section__second__services .swiper {
  height: 100%;
}

.section__second__services .swiper-slide {

  width: 1000px; /* Set the width for each slide */
  height: 100%; /* Set the height for each slide */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  font-size: 24px;
  color: #fff;
}

.swiper-button-prev {
  left: 10px;
}

.swiper-button-next {
  right: 10px;
}

.second-swiper-slide {
  width: 80%;
  height: 100%;
}
.second-swiper-block-container{
  width: 100%;
  height: 100%;
}
.second-swiper-nameandlogo{
  display: flex;
  align-items: center;
  height: 87px;
}
.second-swiper-Logo{
  height: 100%;
  width: 106px;
  margin-right: 40px;
}
.second-swiper-Logo img{
  width: 106px;
  height: 87px;
}

.second-swiper-name{
  height: 100%;
  display: flex;
  flex-direction: column;

}
.second-swiper-name p {
font-size: 48px;
font-weight: 600;
text-align: left;
color: #fff;
}
.second-swiper-name div{
  width: 135px;
  height: 1px;

  margin-top: 32px;
}
.second-swiper-desc{
  margin-top: 48px;

}
.second-swiper-desc p{
  font-size: 16px;
  font-weight: 400;
  line-height: 23.9px;
  text-align: left;
  color: #fff;
  margin-bottom: 20px;
}
.custom-arrow {

  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;

 

}

.swiper-button-prev.custom-arrow {
  left: 10px; /* Adjust as necessary */
  color: #0000000c;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #07E6D9 0%, #1C423F 89.58%);
  position: absolute;
}
.swiper-button-prev.custom-arrow img {
  position: absolute;
  width: 32px;
  height: 32px;
}
.swiper-button-next.custom-arrow  img{
  position: absolute;
  width: 32px;
  height: 32px;
}
.swiper-button-next.custom-arrow {
  right: 10px; /* Adjust as necessary */
  color: #0000000c;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #07E6D9 0%, #1C423F 89.58%);
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled{
  background: linear-gradient(135deg, #180B0B 0%, #1C423F 89.58%);

}
.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled{
  background: linear-gradient(135deg, #180B0B 0%, #1C423F 89.58%);
}
.swiper__desc {
  min-height: 126px;
}
.swiper_name{
  min-height: 78px;
}
.swiper__number {
  font-size: 110px;
}
@media only screen and (max-width: 790px) {
  #services {
    margin-top: 120px;
    background-position: center;
    min-height: initial;
  }
  .swiper__content__container{
    padding: 20px;
  }
  .swiper__number {
    font-size: 48px;
  }
  .swiper__image {
    height: 50px;
  }
  .swiper__image img {
    height: 100%;
  }
  .swiper_name {
    font-size: 26px;
    margin-bottom: 0px;
  }
  .swiper__content__container{
    justify-content: space-between;
  }
  .services__wrapper__swiper{
    margin-top: 50px;
  }
  .section__second__services{
    display: none;
    visibility: hidden;
  }
  .section__services__container{
    min-height: initial;
  }
  .section__services__background{
    min-height: initial;
  }
  .swiper__desc {
    font-size: 12px;
    min-height: 167px;
  }

}