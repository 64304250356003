#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #242424; /* Add background color to hide content */
    z-index: 9999; /* Ensure it's above all other elements */

    border: 1px solid;

border-image-source: linear-gradient(152.69deg, #646464 3.41%, rgba(0, 0, 0, 0) 82.98%);


  }
  
  #loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 250px;
    height: 250px;
    margin: -125px 0 0 -125px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #156864;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  #loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #29c4bc;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
  }
  
  #loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #58f8f0;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
  .preloader_image{
    position: absolute;
    height: 40px;
    width: 150px;

    top: calc(50% - 10px);
    left: calc(50% - 75px);
  }
  .preloader_image img {
    width: 100%;
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  