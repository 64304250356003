.soft {
    width: 100%;
    max-height: 700px;
}

.soft__container {
    width: 100%;
    height: 100%;
}

.soft__name {
    position: relative;
    margin: 0 auto;
    height: 52px;
    width: 175px;

}

.soft__name p {
    color: #fff;
    font-size: 52px;
    font-weight: 600;
    line-height: 63.39px;
    text-align: center;

}

.soft__name img {
    position: absolute;
    right: 10px;
    bottom: 0;
    height: 12px;
    width: 12px;

}

.soft__elements__wrapper {
    width: 1240px;
    margin: 0 auto;
    margin-top: 75px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.soft__elements__wrapper img{
    max-width: 55px;
}

.soft__placeholder__container {
    height: 100px;
    width: 430px;
font-size: 16px;
font-weight: 500;
line-height: 19.81px;
letter-spacing: 0.1em;
text-align: justify;

}

.soft__element__container {
    width: 380px;
    height: 147px;
    padding: 20px 20px 36px 36px;
    border-radius: 16px;
    margin-bottom: 35px;
    filter: blur();
    border: 1px solid #433e42;
    position: relative;
    background: linear-gradient(180deg, rgba(67, 62, 66, 0.2699054621848739) 0%, rgba(255, 255, 255, 0) 100%);
}


.soft__element__percentage {
    position: absolute;
    right: 25px;
    top: 24px;

    font-size: 22px;
    font-weight: 400;
    line-height: 32.87px;
    color: #fff;

}

.soft__element__name {
    display: flex;
    align-items: flex-end;
}

.soft__element__name p {

    font-size: 30px;
    font-weight: 600;
    line-height: 36.57px;
    text-align: left;
    color: #FFFFFF66;
    margin-left: 10px;
}
.soft__element__rating{
    display: flex;
    height: 21px;
    margin-top: 20px;
}
.soft__rating__block {
    height: 21px;
    width: 12.24px;
    border: 2px solid #FFFFFF;
    border-radius: 4px;
    margin-right: 15px;
  }
  
  .soft__rating__block.filled {
    background-color:  #1F9D96;
    border: 2px solid #1F9D96;
; /* Set your desired fill color */
  }


  @media only screen and (max-width: 768px) {
    .soft__element__container{
        width: 170px;
        height: 112px;
        padding: 10px;
        margin-top: 10px;
        border-radius: 7px;
    }
    .soft__element__name p{
        font-size: 18.5px;
        line-height: initial;
        margin-top: 0px;
    }
    .soft__element__name img{
        height: 30px;
        
    }
    .soft__element__percentage{
        font-size: 11.5px;
        line-height: initial;
        top: 6px;
        right: 10px;
    }
    .soft__element__rating{
        margin-top: 15px;
    }
    .soft__rating__block{
        height: 41px;
        margin-right: 9.7px;
        border-radius: 1.5px;
    }
    .soft__placeholder__container{
        width: 100%;
        order: 0;
        height: 100%;  
        order: 0;
        margin-bottom: 10px;
    }
    .soft__element__container:nth-child(1){
        order: 1;
    }
    .soft__elements__wrapper{
        width: 90%;
        margin-top: 38px;
        justify-content: space-evenly;
    }
    .soft__placeholder__container{
        font-size: 13px;
    }
    .porfolio__name{
        justify-content: center;
    }
  }