.contact {
  width: 100%;
  min-height: 500px;
}

.contact__wrapper {
  width: 100%;
  height: 100%;
  margin-top: 100px;
  position: relative;
}

.contact__desc {
  max-width: 1000px;
  margin: 0 auto;
}

.contact__desc p {
  color: #fff;
  font-size: 48px;
  font-weight: 600;
  line-height: 58.51px;
  text-align: center;
}

.contact__container {
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 70px;
  z-index: 300;
  position: relative;
}

.contact__image {
  height: 348px;
  width: 348px;
  border-radius: 50%;
  background-color: #000;
  border: 15px solid #1f9d96;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}








/*************/

@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

:root {
  --card-height: 65vh;
  --card-width: calc(var(--card-height) / 1.5);
}

.card {

  position: relative;

  justify-content: center;
  align-items: center;
  text-align: center;

}

.card:hover {
  color: rgb(88 199 250 / 100%);
  transition: color 1s;
}
.card:hover:before, .card:hover:after {
  animation: none;
  opacity: 0;
}


.card::before {
  content: "";
  height: 333px;
  width: 333px;
  border-radius: 50%;
  background-image: linear-gradient(
    var(--rotate)
    ,#1f9d96, #3c67e3 50%, #4e00c2);
    position: absolute;
    z-index: -1;
    top: -1%;
    left: 0%;
    animation: spin 2.5s linear infinite;
}

.card::after {
  position: absolute;
  content: "";
  top: 0%;
  left: 0;
  right: 0;
  z-index: -1;
  height: 348px;
  width: 348px;
  margin: 0 auto;
  transform: scale(0.8);
  filter: blur(80px);
  border-radius: 50%;
  background-image: linear-gradient(
    var(--rotate)
    , #5ddcff, #3c67e3 43%, #4e00c2);
    opacity: 1;
  transition: opacity .5s;
  animation: spin 2.5s linear infinite;
}

@keyframes spin {
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
}



/*******************/

.contact__image-name {
  position: relative;
  height: 52px;
  width: 220px;
}

.contact__image-name p {
  color: #fff;
  font-size: 50px;
  font-weight: 600;

  text-align: center;
}

.contact__image-name img {
  position: absolute;
  right: -15px;
  bottom: 5px;
}

.contact__form {
  width: 861px;
  height: 527px;
  border: 1px solid;
  background-image: url("../assets/daker_back.png");
  background-position: center;
  background-size: cover;
  border-radius: 16px;

}

.contact__form-form {
  width: 100%;
  height: 100%;
  background: rgb(67, 62, 66);
  background: linear-gradient(180deg,
      rgba(67, 62, 66, 0.2699054621848739) 0%,
      rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#433e42", endColorstr="#ffffff", GradientType=1);
  border-radius: 16px;
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  padding: 32px 27px;
}

.contact__form-input_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.contact__form-input__data {
  width: 400px;
}

.input-group {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input-group label {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 16px;
}

.input-group-input {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;

}

.input-group input {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-bottom: 3px solid #1f9d96;
  padding-left: 25px;

  color: #1f9d96;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;

}

.input-group input::placeholder {
  color: #1f9d96;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
}

.input-group:nth-child(2) {
  margin-top: 26px;
}

.input-group:nth-child(3) {
  margin-top: 26px;
}

.contact__form-input__data2 {
  width: 370px;
}

.contact-textarea {
  width: 100%;
  height: 128px;
  border-radius: 8px;
  overflow: hidden;
}

.contact-textarea textarea {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-bottom: 3px solid #1f9d96;
  padding: 10px;

  color: #1f9d96;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  resize: none;
  /* Disable resizing */
}

.contact-textarea textarea::placeholder {
  color: #1f9d96;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
}

.radio-group label {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

}

.radio-group {
  display: flex;
  justify-content: space-between;
}

.radio-block-group {
  width: 91px;
  display: flex;

  cursor: pointer;
}

.radio-block-group input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.radio-block-group label {
  margin-bottom: 0;
  cursor: pointer;
}






/* Custom Radio Button */
.custom-radio {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.custom-radio+label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 16px;
  color: #ffffff;
}

.custom-radio+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #00D1B2;
  background-color: #fff;
  box-sizing: border-box;
}

.custom-radio:checked+label:before {
  background-color: #00D1B2;
}

.custom-radio:checked+label:after {
  content: "";
  position: absolute;
  left: 7px;
  top: 50%;
  transform: translateY(-50%);

  border-radius: 50%;
  background-color: white;
}

/* Custom Checkbox */
.custom-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.custom-checkbox+label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 16px;
  color: #ffffff;
}

.custom-checkbox+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 2px solid #00D1B2;
  background-color: transparent;
  box-sizing: border-box;
}

.custom-checkbox:checked+label:before {
  background-color: #00D1B2;
}

.custom-checkbox:checked+label:after {
  content: "";
  position: absolute;
  left: 7px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background-color: white;
}




.contact__form-terms {

  cursor: pointer;
  margin-top: 35px;
}

.contact__form-terms label {

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  margin-top: 12px;
  margin-left: 6px;

}
.contact__form-terms  a {
  margin-left: 10px;
  color: #fff;
  font-weight: 600;
}
.contact__form-terms  a:hover {
  color: #1F9D96;
}

.contact__form-terms .input-group {
  display: flex;
  height: 30px;
  justify-content: initial;
  flex-direction: row;
  align-items: center;

}

.contact__form-terms #terms {}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 22px;
  height: 28px;
  border: 2px solid #1f9d96;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.3s ease;
}


/* When the checkbox is checked */
input[type="checkbox"]:checked {
  background-color: #1f9d96;
}

/* Optional: Change the color when hovering */
input[type="checkbox"]:hover {
  border-color: #fff;
}




.contact__form-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.contact__form-button button {
  width: 280px;
  height: 48px;
  background-color: #1f9d96;
  border: 1px solid #fff;
  border-radius: 40px;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact__form-button button p {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  text-align: center;
  margin-right: 20px;
}

.contact__social__ {
  height: 158px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 53px;

  z-index: 300;
  position: relative;


}

.contact_poligon {
  position: absolute;
  z-index: 100;

}

.contact_poligon1 {
  bottom: 0;
}

.contact_poligon2 {

  left: 0;
  bottom: 16%;
}

.contact_poligon3 {
  left: 0;
  bottom: 0;
}

.contact_poligon4 {
  right: 0;
  bottom: 0;
}

.contact_poligon5 {
  right: 0;
  bottom: 0;
}

.contact_poligon6 {
  right: 0;
  bottom: 0;
}

.contact__social__daker {
  height: 25px;
  width: 115px;

}

.contact__social__daker img {
  height: 100%;
  width: 100%;

  object-fit: cover;
}

.contact__social__social {
  width: 550px;
  z-index: 500;
  height: 45px;

}

.contact__social__social ul {
  display: flex;
  justify-content: space-between;
}

.contact__social__social ul a {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;


  height: 45px;
  width: 45px;
  border-radius: 6px;
  background-color: #9D9D9D80;

}

.contact__social__social ul a:hover {
  background: linear-gradient(180deg, #1F9D96 0%, #9D9D9D 100%);

}

.contact__social__text {
  position: absolute;
  width: 305px;
  top: -150px;
}

.contact__social__text1 {
  left: -75px;
  transform: rotate(90deg);

  text-align: right;
}

.contact__social__text2 {
  right: -75px;
  transform: rotate(-90deg);

}

.contact__footer {

  height: 158px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 150px 0px 53px;


}
.contact__form-terms{
  margin-bottom: 5px;
}
@media only screen and (max-width: 1610px) {
  .contact__social__social {
    margin-right: 10%;
  }

}

@media only screen and (max-width: 1500px) {
  .contact__container {
    width: 92%;
  }

  .contact__social__text2 {
    right: -120px;
  }

  .contact__social__text1 {
    left: -120px;
  }

  .contact__social__social {
    margin-right: calc(165px);
  }

  .contact__image {
    margin-left: 63px;
  }

}

@media only screen and (max-width: 1400px) {
  .contact__image {
    margin-left: 0px;
  }

  .contact__social__social {
    margin-right: 7%;
  }


}

@media only screen and (max-width: 1290px) {
  .contact__image {
    width: 280px;
    height: 280px;
    border: 10px solid #1f9d96;
  }

  .contact__image-name p {
    font-size: 40px;

  }

  .contact__image-name img {
    right: 8px;
    bottom: 17px;
    height: 8px;
    width: 8px;
  }

  .contact__form-input__data {
    width: 380px;
  }

  .contact__form {
    width: 847px;
  }
}

@media only screen and (max-width: 790px) {
  .contact__container {
    flex-direction: column;
    align-items: center;
  }

  .contact__desc {
    width: 90%;
    margin: 0 auto;
    z-index: 500;
    position: relative;
  }

  .contact__desc p {
    font-size: 20px;
    line-height: 30px;

  }

  .contact__image {
    width: 240px;
    height: 240px;
    border: 7px solid #1F9D96;
  }

  .contact__image-name img {
    width: 8px;
    height: 8px;
  }

  .contact__image-name p {
    font-size: 37px;
  }

  .contact__image-name {
    height: 38px;
    width: 156px;
  }

  .contact__container {
    margin-top: 0;
  }

  .contact__social__text2 {
    right: initial;
    top: initial;
    transform: initial;
    bottom: 10px;
  }

  .contact__social__text1 {
    display: none;
    visibility: hidden;
  }

  .contact_poligon img {
    width: 100%;
  }

  .contact_poligon3 {}

  /***!! *******************/
  /***!! *******************/
  /***!! *******************/
  /***!! *******************/
  /***!! *******************/
  .contact__form {
    width: 95%;
    height: initial;
  }

  .contact__form-form {
    padding: 20px;
  }

  .contact__form-input_container {
    flex-direction: column;
  }

  .contact__form-input__data {
    width: 100%;
  }

  .contact__social__social ul a {
    width: 35px;
    height: 35px;
  }

  .contact__social__social ul a img {
    width: 20px;
    height: 20px;
  }

  .contact__social__daker {
    display: none;
    visibility: hidden;
  }

  .contact__social__ {
    align-items: center;
    justify-content: center;
  }

  .contact__social__social {
    margin-right: 0;
  }

  .contact__form {
    margin-top: 30px;
  }

  .radio-group {
    flex-wrap: wrap;
    align-items: center;
  }

  .radio-block-group:nth-child(3) {
    margin-top: 10px;
  }

  .contact__social__social {
    width: 100%;
  }

  .contact__social__ {
    padding: 0 20px;
  }

  .radio-block-group:nth-child(3) {
    margin-top: 0;
  }
  .input-group input {
    padding-left: 10px;
  }
  input[type="checkbox"] {
    width: 40px;
  }
  .contact__footer{
    z-index: 500;
  }
  .contact__footer{
    flex-direction: column-reverse;
    justify-content: initial;
    align-items: center;
    padding: 0px 30px 40px 30px;
  }
  .contact__footer_image{
    margin-top: 10px;
  }
  .card::before {
    width: 240px;
    height: 240px;
    top: -3%;
    left: -3%;
}
.card::after{
  top: -22%;
  left: -35px;
}
.contact__image-name p {
  font-size: 30px;
}
.contact__image-name img {
  right: 2px;
  bottom: 12px;
}
input[type="checkbox"] {
  width: 42px;
  }
  .contact__form-terms label {
    font-size: 10px;
    line-height: 15px;
  }
}
@media only screen and (max-width: 360px) {
      .radio-block-group:nth-child(3){
        margin-top: 8px;
      }

      input[type=checkbox] {
        width: 28px;
      }
      .contact__form-terms label{
        max-width: 200px;
        font-size: 11px;
      }
}