@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

#hero {
  width: 100%;

}
.section__hero__container {
  min-height: 868px;
  width: 100%;
  position: relative;
  top: -124px;

}
.hero_z {
  z-index: 200;
}
.hero__social {
  position: absolute;
  left: 0;
  width: 243px;
  height: 100%;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.24) 0%,
    rgba(255, 255, 255, 0.16) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.24) 0%,
    rgba(255, 255, 255, 0.16) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.24) 0%,
    rgba(255, 255, 255, 0.16) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}

.hero__social__container {
  width: 45px;
  position: absolute;

  left: calc(50% - 22.5px);
  top: 223px;
  height: 430px;
}
.hero__social__container ul {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.hero__social__container ul li {
  width: 45px;
  height: 45px;
  background-color: #9d9d9d80;
  border-radius: 6px;
}

.hero__social__container ul li a {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hero__social__container ul li:hover {
  background: rgb(31, 157, 150);
  background: -moz-linear-gradient(
    180deg,
    rgba(31, 157, 150, 1) 0%,
    rgba(157, 157, 157, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(31, 157, 150, 1) 0%,
    rgba(157, 157, 157, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(31, 157, 150, 1) 0%,
    rgba(157, 157, 157, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1f9d96",endColorstr="#9d9d9d",GradientType=1);
}
.hero__social__container img {
  height: 33px;
  width: 33px;
}

.hero__general {
  position: absolute;
  height: 441px;

  top: calc(50% - 220.5px);
  left: 20%;
}
.hero__genaral__about {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hero__general__name p:nth-child(1) {
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 400;
  line-height: 48.76px;
  text-align: left;
  color: #ffffffb2;
}
.hero__general__name p:nth-child(2) {
  font-family: Montserrat;
  font-size: 52px;
  font-weight: 600;
  line-height: 63.39px;
  text-align: left;
  margin-top: 22px;
}
.hero__general__desc {
  width: 429px;
  margin-top: 40px;
}
.hero__general__desc p {
  margin-bottom: 10px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: justified;
}
.hero__general__desc p:last-child {
  margin-bottom: 0;
}

/****************************/


.hero__skills {
  position: absolute;
  width: 54%;
  background-color: #9D9D9D80;
  height: 198px;
  bottom: -160px;
  border-radius: 100px;
  right: calc(15%);
}
.hero__skills__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

}
.hero__skills__element {
  height: 164px;
  width: 111px;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 42px;
}
.hero__skills__element:nth-child(1){
  margin-left: 73px;
}

.skills__element__circle {
  width: 94px;
  height: 94px;
  background: linear-gradient(180deg, #1f9d96 0%, #9d9d9d 100%);
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.skills__element__desc {
  font-size: 14px;
  font-weight: 500;
  line-height: 20.92px;
  text-align: center;
  height: 63px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hero__image__daker {
  height: 215px;
  width: 525px;
  border-radius: 118px;
  background-color: #9d9d9d;
  position: absolute;
  right: -200px;
  top: -70px;
  background-image: url("../assets/daker.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 300;
}
.hero__image__main{
  position: absolute;
  bottom: 0;
z-index: 200;
right: 105px;
bottom: calc(100% - 110px);
height: 650px;

}
.hero__image__main img{
  height: 100%;
}
.hero_rectangle{

    z-index: 100;
    position: absolute;
}
.hero_rectangle img{
  width: 100%;
}
.hero_rectangle1 {
    right: 0;
    bottom: 10%;
    opacity: 0.8;
}

.hero_rectangle2{
    bottom: 0;
    left: 10%;
    opacity: 0.9;
}
.hero_rectangle3{
    top: 0%;
    left: 14%;
    opacity: 1;
}
.hero_rectangle4{
    right: 0;
    bottom: -10%;
}

.hero_rectangle5{
  left: 0;
  top: 0;
  opacity: 1;
}
.hero_rectangle6{
  left: 20%;
  top: 0;
  opacity: 1;
}
@media only screen and (max-width: 1610px){
  .hero__skills {
    width: 64%;
    right: 20%;
  } 
}
@media only screen and (max-width: 1680px) {
  .hero__additional {
    left: calc(50% - 523px);
  }
  
}
@media only screen and (max-width: 1500px) {
  .hero__additional{
    left: calc(50% - 580px);
  }
  .hero__general {
    left: 21%;
  }
  .hero__skills {
    width: 921px;
    right: 24%;
  }
}
@media only screen and (max-width: 1450px) {
  .hero__skills {

  }
  .hero__additional{
    width: 965px;
    left: calc(50% - 513px);
  }
  .hero__skills{
    right: 22%;
  }
  .hero__image__daker {
    height: 191px;
    width: 469px;

  }
  .hero__image__main{
    height: 580px;
    bottom: calc(100% - 97px);
    right: 99px;
  }
  
  .hero__skills {
    bottom: -97px;
  }
}
@media only screen and (max-width: 790px) {
  .hero__social{
    display: none;
    visibility: hidden;
  }
  .hero__additional {
    left: 0;
    width: 100%;
  }
  .hero__image__daker {
    height: 120px;
    width: 360px;
    right: calc(50% - 180px);
}
.hero__image__main{
  transform: scale(0.8);
  right: -202px;
  bottom: calc(100% - 128px);
}
.hero__general__name p:nth-child(1) {
  font-size: 18px;
}
.hero__additional{
  background-color: initial;
}
.hero__skills{
  left: 0;
}
.hero__skills__wrapper{
  justify-content: space-evenly;
}
.skills__element__circle{
  width: 60px;
  height: 60px;
}
.skills__element__circle img{
  width: 30px;height: 30px;
}     .hero__general {
  left: 4%;
}
.hero__general__name p:nth-child(2) {
  font-size: 37px;
  line-height: initial;
  margin-top: 0;
}
.hero__general__desc{
  display: none;
  visibility: hidden;
}

.hero__general {
  top: calc(50% - 291.5px);
}
.hero__image__daker {
  right: calc(50% - 180px);
  top: -153px;
}

.hero__image__main {
  transform: scale(0.8);
  right: 5px;
  bottom: calc(100% - 25px);
}
.hero__skills__wrapper{
  width: 330px;
  margin: 0 auto;
  flex-wrap: wrap;
}
.hero__skills{
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff00;
}
.hero{
  width: 100%;
}.section__hero__container{
  width: 100%;
}
.hero_rectangle6{
  left: 0;
  overflow: hidden;
}
.hero_rectangle3 {
  transform: rotate(-90deg);
  left: -15%;
}
.hero_rectangle2{
  bottom: 40%;
}
.hero_rectangle4 {
  opacity: 1;
}
.hero__image__main{
  width: 320px;

  right: calc(50% - 160px);
}
.hero__image__main img{
  width: 100%;

}
.hero__additional {
  bottom: calc(0% - 40px);
}
.hero__skills__element:nth-child(1){
  margin-left: 0;
}.hero__image__main {
  bottom: calc(100% - 136px);
  right: calc(50% - 145px);
}
.hero__skills__element{
  margin-right: 0px;
}
}
@media only screen and (max-width: 390px) {
  .hero__image__daker{
    right: calc(50% - 136px);
    height: 107px;
    width: 276px;
  }
  .hero__image__main {
    right: calc(50% - 154px);
  }
  .hero__general___name p:nth-child(2) {
    font-size: 31px;
  }
  .about__desc__element div {
    font-size: 40px;
  }
  .about__desc__element p{
    font-size: 18px;
  }
  .hero__image__main {
    height: initial;
    right: calc(50% - 116px);
        height: initial;
        width: 267px;
        z-index: 100;
        bottom: calc(100% - 113px);
  }
  
  .hero__image__daker {
    height: 107px;
        right: calc(50% - 145.5px);
        width: 291px;
        z-index: 400;
  }











  

}